@font-face {
  font-family: "Montserrat-Light";
  src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat" !important;
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

* {
  font-family: "Montserrat" !important;
}

h6 {
  font-family: "Montserrat-Medium";
}

:root {
  --primary-red: #fc1921;
  --primary-black: #000000;
  --secondary-medical-green: #03b3be;
  --secondary-sky-blue: #88c0f0;
  --secondary-plasma-gold: #ffa814;
  --seoncdary-maroon: #851c1d;
  --soft-white: #fcfcfa;
  --off-white: #f1efea;
  --warm-gray: #e2dfda;
}

body {
  background-color: #f7f8fa;
  margin: 0;
}


.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.card-cus {
  border: none;
}
.dashboardH3 {
  display: flex;
  font-size: 1.5rem;
  font-weight: 600;
}

.button-custom-grey {
  border: none !important;
  border-radius: 0 !important;
  color: black;
}

.button-custom-grey:hover {
  color: black !important;
  background-color: lightgray !important;
}

.form-label-custom {
  font-size: 14px;
  font-weight: 700;
  align-content: center;
  margin-top: 5px;
}

.create-user-button {
  align-content: center;
  text-align: center;
  background-color: #fc1921;
  border: none;
  border-radius: 30px;
  height: 60px;
  margin: auto;
}

.create-user-button:hover {
  background-color: #fc1921 !important;
}

.navigate_item {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 0.6rem;
  white-space: nowrap;
}

.navigate_line {
  opacity: 0.10000000149011612;
  border: 2px solid #000000;
  height: 2px;
  width: 100%;
  margin-top: -3px;
  background-color: #000000;
}

.process_title {
  color: #000000;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 22px;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 35px;
}

.width-cus85 {
  width: 85%;
}

.div-svg {
  width: 2.5rem;
  height: 2.5rem;
  background: #8bb59e45;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-spin-nested-loading {
  height: 100%;
}

.border-radius {
  background: red;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  height: 4rem;
  clip-path: polygon(0px 25px, 100% 0px, 100% 100%, 0% 100%);
  margin-top: 22px;
}
.border-radiusNew {
  height: 5rem;
  padding: 1rem 1.5rem;
  background: red;
  /* border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem; */
  clip-path: polygon(0px 25px, 100% 0px, 100% 100%, 0% 100%);
}

.border-red {
  height: 0.3rem;
  background: red;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  width: 5rem;
}

.btn-red {
  background: red;
  height: 2rem;
  padding: 0px 1rem;
  border-color: red;
  border-radius: 1rem;
  font-size: 0.9rem;
  font-weight: 700;
}
.btn-red:hover {
  background: red;
  border-color: red;
}
.btn-download {
  display: inline-block;
  padding: 2px;
  cursor: pointer;
  color: rgb(96, 96, 246);
  background-color: rgb(161 161 237 / 42%);
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 0.2s;
}
.div-qr {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #ff000066;
  border-radius: 5px;
}

.border-qr {
  border: 3px solid #ff000066;
  border-radius: 5px;
}

.div-form {
  padding-top: 0rem;
  padding-bottom: 0rem;
  padding-left: 6rem;
  padding-right: 6rem;
}

.h1-26px {
  font-size: 26px;
  font-weight: 500;
}

.red-box-cut {
  background: #fc1921;
    text-align: center;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    width: 11rem;
    height: 79px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3rem;
    color: #fff;

    color: #fff;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.3em;
}

.ant-upload-list .ant-upload-list-text {
  display: none;
}
.form-control:disabled {
  background-color: #e9ecef52 !important;
  opacity: 1;
}

.div-img-main {
  position: relative;
  display: inline-block;
}
.img-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 5px;
}
.div-img-main:hover .img-hover {
  opacity: 1;
}

.cus-nav-tab-scroll::-webkit-scrollbar {
  display: block;
}

::-webkit-scrollbar {
  display: none;
}


.cus-scroll-content::-webkit-scrollbar {
  display: block !important;
}
.cus-scroll-content::-webkit-scrollbar {
width: 0.2rem ;
height: 5px;
}

.cus-scroll-content::-webkit-scrollbar-track {
background-color: #f5f5f5 ;
}

.cus-scroll-content::-webkit-scrollbar-thumb {
background-color: #dee2e6 ;
height: auto;
border-radius: 10px;
}

.cus-scroll-content::-webkit-scrollbar-thumb:hover {
background-color: #555 ;
}
.cus-scroll-content::-webkit-scrollbar {
  display: block;
}

.div-content-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-bg-blue {
  background-color: #0d6efd !important;
  min-width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.btn-bg-blue:hover {
  background-color: #0d6efd !important;
}

.div-phone {
  background-size: contain;
  background-position: center center;
  border-radius: 2rem;
  width: 350;
  height: 750px;
  margin: auto;
  border: none;
  background-repeat: no-repeat;
}

.hw-1290-1080 {
  width: 688px;
  /* 1290; */
  height: 399px
  /* 1080; */
}
.modal-preview .modal-dialog {
 /* min-width: 688px;
 min-height: 399px; */
}

.tick-div {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8bb59e45;
}
.close-div {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff000045;
  color: red;
}

.cus-error {
  border-width: 1px;
  border-style: solid ;
  border-color: #ff4d4f !important;
}

.w-full-120-03 {
  width: calc(100% - 120px - 0.3rem);
}

.text-label {
  font-weight: 400;
  opacity: 0.6;
}

.text-label2 {
  font-weight: 400;
  opacity: 1;
}

.div-10px {
  width: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-create-preview {
  padding-left: 3rem;
  padding-right: 3rem;
}

.bnt-ipad-cus {
  width: 31px;
  height: 3px;
  background: black;
  display: block;
  margin-left: 2px;
  border-radius: 10px;
}

.bnt-ipad-left-cus {
  height: 41px;
  width: 3px;
  background: black;
  position: absolute;
  margin-top: 2.5rem;
  margin-left: -3px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .div-form {
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 991px) {
   .modal-preview .modal-dialog {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      max-width: 90% !important;
  }
}
@media (min-width: 801px) {
  .modal-preview .modal-dialog {
     margin-left: auto;
     margin-right: auto;
     width: 90%;
 }
}


@media (max-width: 820px) {
 .div-form {
  padding-top: 0rem;
  padding-bottom: 0rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.div-create-preview {
  padding-left: 1rem;
  padding-right: 1rem;
}

}

@media (max-width: 430px) {
  .modal-preview .modal-dialog {
     margin-left: auto;
     margin-right: auto;
     /* width: 90%;
     max-width: 90% !important; */
  width: 804px !important;
 }
  .modal-preview .modal-dialog .modal-content {
    margin-left: auto;
    margin-right: auto;
    /* width: 90%;
    max-width: 90% !important; */
    width: 804px !important;
  }
  .div-form {
   padding-top: 0rem;
   padding-bottom: 0rem;
   padding-left: 1rem;
   padding-right: 1rem;
 }
}
.font-0-875 {
  font-size: 0.875rem;
}

.div-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}

.require_cus {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.preview-pad {
  /* overflow: auto;
  border-radius: 1rem;
  height: 31rem;
  max-height: 31rem;
  width: 90%;
  margin: auto;
  margin-top: 8rem; */
}
.div-pad {
  background-size: contain;
  background-position: center center;
  border-radius: 2rem;
  /* width: 597px; */
  height: 555px;
  margin: auto;
  border: none;
  background-repeat: no-repeat;
}
.div-pad-preview {
  background-size: contain;
  background-position: center center;
  border-radius: 2rem;
  /* width: 597px; */
  /* height: 555px; */
  margin: auto;
  border: none;
  background-repeat: no-repeat;
}

.div-pad-body {
  width: 711.5px;
  height: 100%;
  margin: auto;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  margin-right: 34px;
}

.border-radius-cus {
  background: red;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  height: 5rem;
  clip-path: polygon(0px 25px, 100% 0px, 100% 100%, 0% 100%);
  margin-top: 30px;
}
.div-pad-body-detail {
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
}


.red-box-cut-cus {
  background: #fc1921;
    text-align: center;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    width: 11rem;
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3rem;
    color: #fff;

    color: #fff;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.3em;
}

.refresh-div {
  font-size: 1rem;
  /* background: #bfb8b8; */
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  display: flex;
  align-content: center;
  border: 1px solid #bfb8b8;
  cursor: pointer;
  padding: 2px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinning-icon {
  display: inline-block;
  animation: spin 2s linear infinite;
}
.ant-skeleton {
  height: 100%;
}
.ant-skeleton-button {
  height: 100% !important;
}
.custom-skeleton-button {
  min-height: 60vh;
}
:where(.css-dev-only-do-not-override-j9bb5n).ant-skeleton.ant-skeleton-active .ant-skeleton-title, :where(.css-dev-only-do-not-override-j9bb5n).ant-skeleton.ant-skeleton-active .ant-skeleton-paragraph>li, :where(.css-dev-only-do-not-override-j9bb5n).ant-skeleton.ant-skeleton-active .ant-skeleton-avatar, :where(.css-dev-only-do-not-override-j9bb5n).ant-skeleton.ant-skeleton-active .ant-skeleton-button, :where(.css-dev-only-do-not-override-j9bb5n).ant-skeleton.ant-skeleton-active .ant-skeleton-input, :where(.css-dev-only-do-not-override-j9bb5n).ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgb(0 0 0 / 0%) 37%, rgba(0, 0, 0, 0.06) 63%);
  background-size: 400% 100%;
  animation-name: css-dev-only-do-not-override-j9bb5n-ant-skeleton-loading;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.btn-download-flyer {
  display: inline-block;
  padding: 2px;
  cursor: pointer;
  color: rgb(96, 96, 246);
  background-color: rgb(161 161 237 / 42%);
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 0.2s;
}
.btn-lang {
  font-size: 1.3rem;
  display: block;
  position: absolute;
  right: 30px;
}
.btn-lang-change {
  display: inline-block;
  padding: 2px;
  cursor: pointer;
  color: rgb(96, 96, 246);
  /* background-color: rgb(161 161 237 / 42%); */
  border: 1px solid;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 0.2s;
  padding-left: 9px;
  padding-right: 9px;
  width: 79px;
}
.btn-lang-change-active {
  border: 1px solid;
  border-color: rgb(33, 194, 115);
  color: rgb(33, 194, 115);
}
.text-transform-capitalize {
  /* text-transform: capitalize; */
}
.text-transform-capitalize-scan {
  text-transform: uppercase;
}
.text-wrap-cus {
  text-wrap: nowrap;
}
.padding-b {
  padding-bottom: 8px !important;
}

.fixed-text-custom {
  padding-bottom: 0.5px !important;
  border-bottom: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.enable-text-custom {
  padding-top: 0.5px !important;
  border-top: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.input-focus-color {
  border-color: #4096ff !important;
  background-color: #ffffff !important;
}

.fixed-input-group:focus .enable-text-custom,
.fixed-input-group:hover .enable-text-custom {
  box-shadow: none !important;
  border-color: #4096ff !important;
  background-color: #ffffff !important;
}

.fixed-text-custom:focus,
.enable-text-custom:focus {
  box-shadow: none !important;
}

.ant-input-status-error:hover {
  border-color: #ff4d4f !important;
}