.submission-form-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.form-header {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.form-header .form-title {
  font-size: 18px;
  font-weight: 600;
}

.form-input-layout {
  padding: 15px 20px 25px 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.form-label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.custom-phonenumber {
  font-size: 14px;
}

.country-modal-flag{
  width: 30px;
  height: 20px;
}

.country-modal-code {
  font-weight: 500;
  font-size: 14px;
  margin-left: 16px;
}

.ant-input-lg {
  padding: 0px 11px !important;
  height: 40px;
}

.ant-form-item-label>label {
  height: auto !important;
}

.ant-input-affix-wrapper-lg {
  padding: 0px 11px !important;
}

.form-input-layout .ant-form-item-label {
  padding-bottom: 10px;
}

.form-input-layout span {
  font-size: 14px;
  font-weight: 500;
}

.form-input-layout input {
  font-size: 14px;
  font-weight: 500 !important;
  font-family: "Montserrat";
}

.form-input-layout .ant-form-item-explain-error {
  font-size: 12px !important;
}

.form-input-layout input:placeholder-shown {
  font-size: 14px !important;
}

@media screen and (max-width: 600px) {
  .custom-phonenumber {
    font-size: 12px;
  }
  .country-modal-code {
    margin-left: 4px;
  }
  .country-modal-flag {
    width: 20px;
    height: 14px;
  }
}

.event-country-modal-flag{
  width: 20px;
  height: 14px;
}

.event-country-modal-code {
  font-size: 12px;
  margin-left: 4px;
  margin-right: 4px;
}

.event-custom-zipcode input{
  height: 33px;
}


.form-submission-btn {
  background-color: var(--primary-red);
  min-width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.form-submission-btn:hover {
  background-color: var(--primary-red) !important;
}

.form-submission-div {
  background-color: var(--primary-red);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 42px;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 500;
}
