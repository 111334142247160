.notfound_body {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
}

.notfound_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#pagenotfound {
  font-weight: 800;
  font-size: 32px;
  color: #3f86c7;
  text-align: center;
  margin-top: 20px;
}

#sorrynotfound {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin-top: 16px;
}

.btnReturn {
  font-weight: 700;
  font-size: 16px;
  border: none;
  padding: 0.25rem 3.5625rem;
  border-radius: 40px;
  gap: 10px;
  background-color: #3f86c7;
  color: white;
  margin-top: 32px;
  transition: all 0.2s;
}

.btnReturn:hover {
  background-color: #30699b;
  color: white;
}

.img-width {
  width: 400px;
}
