.passwordH3{
    font-size: 20px;
    font-weight: 700;
}
.carborderd1 {
    width: 70%;
    height: 380px;
    margin: auto;
}
@media (max-width: 767px) {
    .auth_body {
        width: 90%;
        margin: auto;
    }

    .button_body {
        width: auto;
    }

    .button_auth {
        width: 100% !important;
        margin: auto;
    }
}
.button_body {
    width: 364px;
}

.button_auth {
    width: 50%;
    height: 60px;
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    justify-content: center;
    margin: auto;
    border: none;
    border-radius: 30px;
    background-color: #FC1921;
    border:3px solid #FC1921 !important;
    transition: all 0.2s ease-in-out;
}

.button_auth:hover {
    color: #FC1921 !important;
    border:3px solid #FC1921 !important;
}