.notfound_body {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.notfound_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#pagenotfound {
  font-weight: 800;
  font-size: 32px;
  color: #b71010;
  text-align: center;
  margin-top: 20px;
}

#sorrynotfound {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin-top: 16px;
}

.btnReturn {
  font-weight: 700;
  font-size: 16px;
  padding: 20px 57px;
  border-radius: 40px;
  gap: 10px;
  background-color: var(--primary-red);
  color: white;
  margin-top: 32px;
  transition: all 0.2s;
  border: solid 2px transparent;
}

.btnReturn:hover {
  background-color: white;
  color: var(--primary-red);
  border: solid 2px var(--primary-red);
}
