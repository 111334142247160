.csl-logo-form {
  background-color: #fc1921;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  width: 25%;
  height: 18%;
  margin-left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3%;
}

.csl-logo-form img {
  width: 100%;
}

.footer-lined {
  background-color: #fc1921;
  clip-path: polygon(0px 25px, 100% 0px, 100% 100%, 0% 100%);
  height: 20%;
}

.event-content {
  padding: 0 30px;
}

.left-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 1vw;
}

.input-event-layout {
  border: 1px dashed rgb(212, 212, 212);
  border-radius: 5px;
  height: 8vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-event-layout p {
  opacity: 0.1;
  font-size: 2vw;
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.input-time-layout {
  border: 1px dashed rgb(212, 212, 212);
  border-radius: 5px;
  padding: 0px 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-time-layout span {
  opacity: 0.1;
  font-size: 1vw;
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.input-event-value {
  font-size: 2vw;
  font-weight: 700;
}

.underlined-red {
  height: 0.3rem;
  background: red;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  width: 30%;
}

.event-time-btn {
  background-color: red;
  height: 3vw;
  width: fit-content;
  padding: 0px 1rem;
  border-color: red;
  border-radius: 1rem;
  font-size: 1.5vw;
  font-weight: 700;
  margin: 0 !important;
}

.display-text {
  color: #000000;
  font-family: Montserrat;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0rem;
  text-align: center;
  text-wrap: nowrap;
}

.csl-image-layout {
  height: 150px;
}

.event-date-modal {
  transform: translate(-9%, 0px) scale(0.9)
}

@media (min-width: 768px) {
  .input-event-layout {
    height: 3rem;
  }
  .input-event-layout p {
    font-size: 0.8rem;
  }
  .input-event-value {
    font-size: 0.8rem;
  }
  .event-time-btn {
    height: 1.3rem;
    font-size: 0.8rem;
  }
  .display-text {
    font-size: 0.5rem;
  }
}

@media (min-width: 992px) {
  .input-event-layout {
    height: 5rem;
  }
  .input-event-layout p {
    font-size: 1.3rem;
  }
  .input-event-value {
    font-size: 1.3rem;
  }
  .event-time-btn {
    height: 2rem;
    font-size: 1rem;
  }
  .display-text {
    font-size: 0.8rem;
  }
  .footer-lined {
    margin-top: 1rem;
  }
  .left-section {
    gap: 2rem;
  }
  .csl-image-layout {
    height: 274px;
  }
}

@media (min-width: 1200px) {
  .display-text {
    font-size: 0.9rem;
  }
}
