.csl-logo {
  width: 120px;
  object-fit: cover;
}

.csl-confined-header {
  padding: 0 15px;
}

.nav-header {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0;
  background-color: #ffff !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
}

.nav-header-form {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffff !important;
}

.nav-header-form .form-title {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.csl-header {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.csl-header .csl-navigation {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-left: 100px;
}

.csl-navigation .csl-nav {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.23s ease-in-out;
  border-bottom: 2px solid transparent;
  color: black;
  padding: 15px 0;
}

.csl-navigation .csl-nav.active {
  border-bottom: 2px solid #2742d5;
  color: #2742d5;
}

.csl-navigation .csl-nav:hover {
  border-bottom: 2px solid #2742d5;
  color: #2742d5;
}

.user-header {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.user-header .user-name {
  font-size: 16px;
  font-weight: 500;
}

.user-header .user-role {
  font-size: 12px;
}

.content-layout {
  padding: 30px 15px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .content-layout {
    padding: 30px 0;
  }
}

@media (min-width: 992px) {
  .csl-header {
    height: 80px;
  }
  .csl-navigation .csl-nav {
    align-items: center;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
  .csl-header {
    padding: 0;
  }
  .csl-confined-header {
    max-width: 1440px !important;
    padding: 0;
    margin: auto;
  }
}
