/* @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600&display=swap'); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.qrcode__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.qrcode__container--parent {
  /* display: flex; */
  gap: 10px;
  align-items: left;
  justify-content: left;
  width: 100%;
}

.qrcode__input {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  /* margin-top: 20px; */
}


.qrcode__input input {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  outline: none;
  resize: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  /* margin-bottom: 15px; */
}

.qrcode__input button, 
.qrcode__download button {
  display: inline-block;
  padding: 2px;
  cursor: pointer;
  color: rgb(96, 96, 246);
  background-color: rgb(161 161 237 / 42%);
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.qrcode__download {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 4px;
}

.qrcode__download button {
  margin-top: 0px;
}

.qrcode__image {
  width: 160px;
  height: 160px;
  padding: 5px;
  border-radius: 5px;
}