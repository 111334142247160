.custom-otp-input input {
  padding: 0px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  max-width: 80px;
  text-align: center;
  text-transform: uppercase;
  overflow: visible;
  text-overflow: clip;
}

.custom-otp-input input:hover,
.custom-otp-input input:focus {
  box-shadow: none !important;
}

.custom-otp-input-error input {
  font-size: 14px !important;
  border-color: #ff4d4f !important;
}

.custom-otp-input {
  display: flex;
  gap: 10px;
}
