.contain_input .border-error {
    border-radius: 10px !important;
    border: 0.15rem solid #ff4d4f !important;
    background-color: rgba(246, 246, 249, 0.4);
    width: 100%;
    height: 3rem;
    font-weight: 600;
    color: #000000;
}

.contain_input .input_field {
    border-radius: 10px !important;
    /* border: 0.15rem solid rgba(21, 44, 91, 0.1) !important; */
    background-color: rgba(246, 246, 249, 0.4);
    width: 100%;
    font-weight: 500;
    color: #000000;
}

.height-cus {
    height: 2.5rem;
}

.contain_input.contain_height {
    margin-top: 18px;
}

.contain_input .label_field {
    color: var(--text-color);
    opacity: 0.6;
    font-size: 1rem;
    font-weight: 600;
}

.contain_input .customLabel_field {
    color: var(--text-color) !important;
    opacity: 0.6 !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
}

.contain_input .require {
    color: var(--red-color);
    margin-left: 1rem;
    font-weight: 700;
    font-size: 18px;
}

.selection_field {
    width: 100%;
    height: 2.5rem;
}

.selection_field .ant-select-selector {
    width: 100%;
    height: 100% !important;
    border-radius: 10px !important;
    border: 0.15rem solid rgba(21, 44, 91, 0.1) !important;
    background-color: rgba(246, 246, 249, 0.4) !important;
    display: flex !important;
    align-items: center;
}

.selection_field .ant-select-selector .ant-select-selection-overflow {
    height: 100%;
}

.selection_field .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item {
    height: 100%;
}

.selection_field_left {
    width: 100%;
    height: 2.8rem;
}

.selection_field_left .ant-select-selector {
    width: 100%;
    height: 100% !important;
    border-radius: 8px !important;
    border: 0.15rem solid rgba(21, 44, 91, 0.1) !important;
    background-color: rgba(246, 246, 249, 0.4) !important;
    display: flex !important;
    align-items: center;
    box-shadow: none !important;
}

.selection_field_left .ant-select-selector .ant-select-selection-overflow {
    height: 100%;
}

.selection_field_left .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item {
    height: 100%;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-size: 0.837rem !important;
    font-weight: 500;
    color: #152C5B;
}

input:placeholder-shown {
    font-size: 0.937rem !important;
}

.input_field input:placeholder-shown {
    padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.font18600 {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-color);
}

.contain_input .ant-radio-wrapper {
    margin-right: 50px;
}

.contain_input .ant-radio {
    transform: scale(1.3);
    margin-right: 10px;
}

.checkbox_layout {
    width: 160px;
    height: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background: #F6FAFD;
    border-radius: 10px;
    border: 1.5px solid rgba(0, 117, 255, 0.90); */
    transition-property: border, background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    background-color: transparent;
    border-radius: 10px;
    border: 1.5px solid rgba(21, 44, 91, 0.08);
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.checkbox_layout .onclick {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
}


.checkbox_layout.active {
    border: 1.5px solid rgba(0, 117, 255, 0.90) !important;
    background-color: #F6FAFD;
}

.checkbox_layout .form-check-label {
    font-size: 15px;
    font-weight: 600;
    color: var(--text-color);
}

.is-invalid {
    border-color: var(--bs-form-invalid-border-color) !important;
}

.react-number-formatter-input {
    border-radius: 10px !important;
    border: 0.15rem solid rgba(21, 44, 91, 0.1) !important;
    background-color: rgba(246, 246, 249, 0.4) !important;
    width: 100% !important;
    height: 2rem !important;
}

.react-number-formatter-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
    transition: box-shadow .15s ease-in-out, box-shadow .15s ease-in-out;
}

.contain_input .ant-picker-input>input {
    color: rgba(21, 44, 91, 0.7) !important;
    font-size: 0.83rem !important;
    font-weight: 500;
}

.contain_input .ant-picker-input>input::placeholder {
    color: rgba(21, 44, 91, 0.7) !important;
    font-size: 0.83rem !important;
    font-weight: 500;
}

.contain_input ::-webkit-input-placeholder {
    color: rgba(21, 44, 91, 0.7) !important;
    font-size: 0.83rem !important;
    font-weight: 500;
}


.pacs-form-css.ant-form .ant-form-item .pace-form-chart-birthday-css.ant-picker {
    background: rgba(246, 246, 249, 0.4);
    border: 0.0938rem solid rgba(21, 44, 91, 0.1);
    width: 100%;
    height: 2.25rem;
    font-size: 15px;
    align-items: center;
    border-radius: 0.625rem;
  }