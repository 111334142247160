input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
html {
  height: 100%;
 }
body {
  /* background-color: #f7f8fa; */
  background-color: rgb(242, 243, 249) !important;
  margin: 0;
  height: 100%;
}
#root {
  height: 100%;
}