.ant-picker-dropdown.ant-picker-dropdown-placement-bottomLeft{
  z-index: 1500;
}

.pacs-completion-donation input{
  padding-left: 0;
}

.ant-message {
  z-index: 10000;
}

.notes_title {
  opacity: 0.800000011920929;
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0rem;
  text-align: center;
}

.preview-layout {
  width: 802px !important;
  min-height: 450px !important;
  max-height: 480px !important;
  background-color: rgb(255, 255, 255);
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.date_title {
  color: white;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0rem;
  text-align: center;
}

.preview-content {
  width: 100%;
  height: auto;
  min-height: 285px;
  padding: 1rem 1.5rem;
  background-color: rgb(255, 255, 255)
}