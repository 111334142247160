.auth-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  gap: 48px;
}

.auth-card {
  width: 100%;
  padding: 36px 30px;
  border-top: 4px outset var(--primary-red);
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.161);
  margin: auto;
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.auth-title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.auth-label {
  margin-bottom: 8px;
  margin-top: 18px;
  font-weight: 700;
  font-size: 14px;
}

.login-button {
  background-color: var(--primary-red);
  border: none;
  border-radius: 30px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 800;
  border-radius: 100px;
  transition: all 0.3s;
  padding: 0 30px;
  margin: auto;
}

.login-button:hover {
  background-color: #ffff !important;
  color: var(--primary-red) !important;
  border: solid 2px var(--primary-red) !important;
}

.ant-input-affix-wrapper-lg{
  padding-left: 0px !important;
}

.password-layout input {
  padding: 0 !important;
}

@media (min-width: 576px) {
  .auth-card {
    width: 540px;
  }
  .login-button{
    width: 300px;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .auth-card {
    width: 660px;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
